<template>
  <v-app>
  	<NavDrawer v-if="loggedIn && cfgLoaded" />
  	<MsgDrawer v-if="loggedIn && cfgLoaded" />
  	<AppBar v-if="loggedIn && cfgLoaded" />
    <v-main>
      <v-scroll-y-transition mode="out-in">
		<router-view v-if="loggedIn && cfgLoaded" :key="$route.fullPath"></router-view>
      </v-scroll-y-transition>
    </v-main>
    <Footer v-if="loggedIn && cfgLoaded" />
	<v-overlay absolute :value="!cfgLoaded">
		<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
	</v-overlay>
  </v-app>
</template>

<script>
import NavDrawer from './views/NavDrawer'; 
import MsgDrawer from './views/MsgDrawer'; 
import AppBar from './views/AppBar'; 
import Footer from './views/Footer'; 
import GenericRoute from './views/GenericRoute'
export default {
  name: 'App',
	components: {
		NavDrawer,
		MsgDrawer,
		AppBar,
		Footer,
	},
	data: () => ({
		cfgLoaded: false,
	}),
	computed: {
		loggedIn() {
			return this.$store.getters['auth/loggedIn']
		}
	},
	beforeMount () {
		this.fetchMe()
	},
	methods: {
		fetchMe() {
			this.$store.dispatch('auth/getMe')
			.then((response) => {
				//console.log(response)
				this.fetchCfgs()
			})
			.catch((error) => {
				console.log(error)
			});
		},
		fetchCfgs() {
			this.$store.dispatch('core/getCfgs')
			.then((response) => {
				//console.log(response)
				//this.getRoutes()
				this.cfgLoaded = true
			})
			.catch((error) => {
				console.log(error)
			});
		},
		getRoutes() {
			const cfgTables = this.$store.getters['core/getCfgTables']
      		cfgTables.forEach(table => {
				let newRoute = {
					path: `/${table.table_name}`,
					component: GenericRoute,
					name: `${table.table_name}_index`,
					props: {
						type: 'list',
						tablename: table.table_name, modelname: table.model_name, 
						tabletitle: table.table_title, modeltitle: table.model_title, 
						refcol: table.refcol,
						group: table.groupname, pos: table.position, icon: table.icon
					},
					children: [{
						path: ':id',
						name: `${table.table_name}_item`,
						component: GenericRoute,
						props: {
							type: 'item',
							tablename: table.table_name, modelname: table.model_name, 
							tabletitle: table.table_title, modeltitle: table.model_title, 
							refcol: table.refcol, 
						},
					}],
				}
				console.log('addRoute:', newRoute)
				this.$router.addRoute(newRoute)
				console.log('Routes:', this.$router.options.routes)
			})
    	},
	},
};
</script>
<style>
@import './sass/custom.css';
</style>