import MainView from '../views/MainView'
import GeneratorView from '../views/GeneratorView'

const routes = [
    {
        path: '/',
        name: 'home',
        component: MainView,
        title: 'Home',
        icon: 'home',
    },
    {
        path: '/clients',
        name: 'clients',
        component: GeneratorView,
        title: 'Clients',
        icon: 'remove_red_eye',
        group: 'Tables',
        meta: { type: 'list', tablename: 'clients', }
    },
    {
        path: '/client/:id',
        name: 'client',
        component: GeneratorView,
        meta: { type: 'item', tablename: 'clients', refname: 'client_id', }
    },
    {
        path: '/customers',
        name: 'customers',
        component: GeneratorView,
        title: 'Customers',
        icon: 'remove_red_eye',
        group: 'Tables',
        meta: { type: 'list', tablename: 'customers', }
    },
    {
        path: '/customer/:id',
        name: 'customer',
        component: GeneratorView,
        meta: { type: 'item', tablename: 'customers', refname: 'customer_id', }
    },
    {
        path: '/things',
        name: 'things',
        component: GeneratorView,
        title: 'Things',
        icon: 'remove_red_eye',
        group: 'Tables',
        meta: { type: 'list', tablename: 'things', }
    },
    {
        path: '/thing/:id',
        name: 'thing',
        component: GeneratorView,
        meta: { type: 'item', tablename: 'things', refname: 'thing_id', }
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: GeneratorView,
        title: 'Invoices',
        icon: 'remove_red_eye',
        group: 'Tables',
        meta: { type: 'list', tablename: 'invoices', }
    },
    {
        path: '/invoice/:id',
        name: 'invoice',
        component: GeneratorView,
        meta: { type: 'item', tablename: 'invoices', refname: 'invoice_id', }
    },
    {
        path: '/products',
        name: 'products',
        component: GeneratorView,
        title: 'Products',
        icon: 'remove_red_eye',
        group: 'Tables',
        meta: { type: 'list', tablename: 'products', }
    },
    {
        path: '/product/:id',
        name: 'product',
        component: GeneratorView,
        meta: { type: 'item', tablename: 'products', refname: 'product_id', }
    },
    {
        path: '/test',
        name: 'test',
        component: MainView,
        title: 'Test',
        icon: 'remove_red_eye',
        group: 'Tests',
    },
    {
        path: '/test2',
        name: 'test2',
        component: MainView,
        title: 'Test2',
        icon: 'remove_red_eye',
        group: 'Tests',
    },
    {
        path: '/test3',
        name: 'test3',
        component: MainView,
        title: 'Test3',
        icon: 'remove_red_eye',
        group: 'Tests',
    },
    {
        path: '/test4',
        name: 'test4',
        component: MainView,
        title: 'Test4',
        icon: 'remove_red_eye',
        group: 'Tests',
    },
    {
        path: '/test5',
        name: 'test5',
        component: MainView,
        title: 'Test5',
        icon: 'remove_red_eye',
        group: 'Tests',
    },
    {
        path: '/files',
        name: 'files',
        component: MainView,
        title: 'Files',
        icon: 'remove_red_eye',
        group: 'Tests',
    },
    {
        path: '/designtests',
        name: 'designtests',
        component: MainView,
        title: 'Designtests',
        icon: 'remove_red_eye',
        group: 'Tests',
    },
    {  	path: '/roleperms',
        name: 'roleperms',
        component: MainView,
        title: 'RolePermissions',
        icon: 'assignment',
        group: 'Administration',
    },
    {
        path: '/configs',
        name: 'configs',
        component: MainView,
        title: 'Configs',
        icon: 'assignment',
        group: 'Administration',
    },
    {  	path: '/cfgtables',
        name: 'cfgtables',
        component: MainView,
        title: 'CfgTables',
        icon: 'assignment',
        group: 'Administration',
    },
    {
        path: '*',
        name: 'undefined',
        redirect: '/',
    },
]
export default routes;
  