// core

const getters = {
	getMenuItems(state) {
		return state.menuItems;
	},
	getMenu(state) {
		return state.menu;
	},
	getMsg(state) {
		return state.msg;
	},

	getCfgTables(state) {
		return state.cfgTables
	},
	getCfgTable: (state) => param => {
		return state.cfgTables.find(e => e.table_name == param.table_name)
	},
	getCfgLists: (state) => param => {
		return state.cfgLists.filter(function (e) {
			return e.pos && e.reftable == param.reftable && e.table_name == param.table_name
		})
	},
	getCfgForms: (state) => param => {
		return state.cfgForms.filter(function (e) {
			return e.pos && e.reftable == param.reftable && e.table_name == param.table_name
		})
	},
	getCfgRefTables: (state) => param => {
		return state.cfgRefTables.filter(function (e) {
			return e.reftable == param.table_name
		})
	},
};

export default getters;