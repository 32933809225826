// files

const mutations = {
	getFile(state, object) {
  		state.file = object;
	},
	setProgress(state, value) {
  		state.progress = value;
	},

};

export default mutations;