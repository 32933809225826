<template>
    <component :is="component" :routeData="routeData" v-if="component" />
</template>
<script>
export default {
    name: 'module',
    props: ['moduleType', 'routeData'],
    data() {
        return {
            component: null,
        }
    },
    mounted() {
        //console.log(this.moduleType, !!this.moduleType)
        if (this.moduleType) {
            this.component = () => import(`./${this.moduleType}`)
        } else {
            this.component = () => import('./default')
        }
    },
}
</script>
<style>
</style>