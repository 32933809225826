// auth

import state from "./state";

const mutations = {
	getMe(state, object) {
  		state.me = object;
	},
	login(state) {
		state.authenticated = true;
	},
	logout(state) {
		state.authenticated = false;
	},
	setAbility(state, object) {
		state.ability = object;
	},

};

export default mutations;