import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import core from './core'
import generic from '../modules/generic/store';
import files from '../modules/files/store'
import roleperms from '../modules/roleperms/store'; 
import cfgtables from '../modules/cfgtables/store';
import configs from '../modules/configs/store';
import abilityPlugin from './auth/ability'

Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [
		abilityPlugin
	],
	modules: {
		auth,
		core,
		generic,
		cfgtables,
		configs,
		roleperms,
		files,
	} 
});
