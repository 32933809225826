// roleperms

import http from '../../../store/api/http' 

const actions = {
    getRolePerms(context) {
		return new Promise((resolve, reject) => {
			http.get('/api/roleperms')
			.then((response) => {
		    	context.commit('getRolePerms', response.data)
				resolve(response)
			})
			.catch((error) => {
				console.log(error)
				reject(error)
			});
		})
    },
    updateRolePerms(context, data) {
		return new Promise((resolve, reject) => {
			http.patch('/api/roleperms', data)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				console.log(error)
				reject(error)
			});
		})
    },

};

export default actions;