// api

import auth from './auth.js' 
import user from './user' 

export default {
	auth,
	user,

};
