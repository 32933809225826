// items

import http from '../../../store/api/http' 

const actions = {
    async getItems(context, param) {
    	//console.log('action->getItems[' + param.tablename + ']')
    	let query = '?page=' + param.page
    	query += (param.parentref ? '&parentref=' + param.parentref : '')
    	query += (param.parentid ? '&parentid=' + param.parentid : '')
    	query += '&sortby=' + (param.sortBy ? param.sortBy : '')
    	query += '&sortdir=' + (param.sortDesc ? param.sortDesc : 'asc')
    	query += '&filter=' + param.filter
    	query += '&paginate=yes'
		try {
			const response = await http.get('/api/' + param.tablename + query)
			let result = response
			/*if(result.data.data) {
				result = response.data
			}*/
			result.tablename = param.tablename
			context.commit("getItems", result);
			return response
		} catch (error) {
			//console.error(error)
			throw error
			//return error
		} finally {
			//console.log('request done.')
		}
    },
    async getItem(context, param) {
    	//console.log('action->getItem[' + param.tablename + ']')
		try {
			const response = await http.get('/api/' + param.tablename + '/' + param.data.id)
			context.commit("getItem", response);
			return response
		} catch (error) {
			//console.error(error)
			throw error
			//return error
		} finally {
			//console.log('request done.')
		}
    },
    async createItem(context, param) {
    	//console.log('action->createItem[' + param.tablename + ']')
		try {
			const response = await http.get('/api/' + param.tablename + '/create')
			context.commit("getItem", response);
			return response
		} catch (error) {
			//console.error(error)
			throw error
			//return error
		} finally {
			//console.log('request done.')
		}
	},
    async editItem(context, param) {
    	//console.log('action->editItem[' + param.tablename + '{' + param.data.id + '}]')
		try {
			const response = await http.get('/api/' + param.tablename + '/' + param.data.id + '/edit')
			context.commit("getItem", response);
			return response
		} catch (error) {
			//console.error(error)
			throw error
			//return error
		} finally {
			//console.log('request done.')
		}
    },
    async saveItem(context, param) {
    	//console.log('action->saveItem[' + param.tablename + ']')
		try {
			if (param.data.id) {
				const response = await http.patch('/api/' + param.tablename + '/' + param.data.id, param.data)
				return response
			} else {
				const response = await http.post('/api/' + param.tablename, param.data)
				return response
			}
		} catch (error) {
			//console.error(error)
			throw error
			//return error
		} finally {
			//console.log('request done.')
		}
    },
    async delItem(context, param) {
    	//console.log('action->getItem[' + param.tablename + ']')
		try {
			const response = await http.delete('/api/' + param.tablename + '/' + param.data.id)
			//context.commit("delItem", param);
			return response
		} catch (error) {
			//console.error(error)
			throw error
			//return error
		} finally {
			//console.log('request done.')
		}
    },
    async getRefItems(context, param) {
    	//console.log('action->getRefItems[' + param.tablename + ']')
    	let query = '?mode=refitemsX'
		try {
			const response = await http.get('/api/' + param.tablename + query)
			response.tablename = param.tablename
			context.commit("getRefItems", response);
			return response
		} catch (error) {
			//console.error(error)
			throw error
			//return error
		} finally {
			//console.log('request done.')
		}
    },
    /*getCfgLists(context) {
    	console.log('action->getCfgLists')
		return new Promise((resolve, reject) => {
			http.get('/api/cfglists')
			.then((response) => {
				console.log(response)
		    	if(typeof(response) === 'object' && response.data instanceof Array) {
					context.commit('getCfgLists', response.data)
		    	} else reject(response)
				resolve(response)
			})
			.catch((error) => {
				console.log(error)
				reject(error)
			});
		})
    },*/

};

export default actions;