// items

const state = {
	items: [],
	item: {},
	refItems: {},
	cfgLists: [],
	
};

export default state;