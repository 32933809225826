// files

import http from '../../../store/api/http' 

const actions = {
    uploadFile(context, data) {
    	console.log('action->uploadFile')
		return new Promise((resolve, reject) => {
			http.post('/api/files', data, {
      			headers: {'Content-Type': 'multipart/form-data'},
    			onUploadProgress: (progressEvent) => {
        			context.commit('setProgress', parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ))
        		},
      		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },

};

export default actions;