// api

import {default as http} from 'axios' 
http.defaults.headers.common['Accept'] = 'application/json'
http.defaults.headers.common['Content-Type'] = 'application/json'
http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
http.interceptors.response.use((response) => {return response;}, function (error) {
    if (error.response.status === 401) {
      if (localStorage.getItem('authenticated')) {
        console.log('unauthorized, removing invalid token, reloading...');
        localStorage.removeItem('authenticated');
      }
      window.location.assign('/');
    }
    return Promise.reject(error);
});
export default http;
