// items

const mutations = {
	getItems(state, obj) {
  		state.items[obj.tablename] = obj.data;
	},
	getItem(state, obj) {
  		state.item = obj.data;
	},
	resetItem(state) {
  		state.item = {};
	},
	getRefItems(state, obj) {
		state.refItems[obj.tablename] = obj.data
	},
	/*getCfgLists(state, objects) {
		state.cfgLists = objects;
	},*/
	/*delItem(state, obj) {
		const items = state.items[obj.tablename].data
		console.log(items)
		items.splice(items.find(e => e.id == obj.data.id), 1)
	},*/
};

export default mutations;