import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { abilitiesPlugin } from '@casl/vue' 
import { ability } from './store/auth/ability'
import store from './store'
import vuetify from './plugins/vuetify'
import './helpers/filters'

Vue.config.productionTip = false

Vue.use(abilitiesPlugin, ability, { useGlobalProperties: true })
store.commit('auth/setAbility', ability)
new Vue({
  router,
  ability,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
