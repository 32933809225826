// MainView

<template>
	<v-container fluid pa-0 ma-0>
		<module :moduleType="moduleType" :routeData="routeData" v-if="moduleType"></module>
	</v-container>
</template>

<script>
	import module from '../modules'
	export default {
		components: {
			module,
		},
		data() {
			return {
				route: '',
				moduleType: null,
				routeData: {},
			}
		},
		computed: {
		},
		created() {
			this.routeData.meta = Object.assign({}, this.$route.meta)
			this.routeData.name = this.$route.name
			this.routeData.params = this.$route.params
			this.moduleType = 'generic'
			switch(this.routeData.meta.type) {
				case 'item':
					this.routeData.rootComponent = 'GenericView'
				break
				case 'list':
					this.routeData.rootComponent = 'GenericList'
				break
				default:
					this.moduleType = this.routeData.name
				break
			}
		},
	}
</script>

<style>
</style>