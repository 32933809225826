// cfgtables

import http from '../../../store/api/http' 

const actions = {
    getCfgTables(context) {
		return new Promise((resolve, reject) => {
			http.get('/api/cfgtables')
			.then(function (response) {
		    	context.commit('getCfgTables', response.data)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    editCfgTable(context, data) {
    	console.log('action->editCfgTable')
		return new Promise((resolve, reject) => {
			http.get('/api/cfgtables/' + data + '/edit')
			.then(function (response) {
		    	context.commit('getCfgTable', response.data)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    createCfgTable(context, data) {
    	console.log('action->createCfgTable')
		return new Promise((resolve, reject) => {
			http.post('/api/cfgtables', data)
			.then(function (response) {
		    	context.commit('getCfgTable', response.data)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    updateCfgTable(context, data) {
    	console.log('action->updateCfgTable')
		return new Promise((resolve, reject) => {
			http.patch('/api/cfgtables/' + data.id, data)
			.then(function (response) {
		    	context.commit('getCfgTable', response.data)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    storeCfgTable(context, data) {
    	console.log('action->storeCfgTable')
		return new Promise((resolve, reject) => {
			context.dispatch(data.id>0 ? 'updateCfgTable' : 'createCfgTable', data)
      		.then((response) => {
				resolve(response)
  			})
	    	.catch((error) => {
				reject(error)
		    });
		})
    },
    getCfgColumns(context) {
		return new Promise((resolve, reject) => {
			http.get('/api/cfgcolumns')
			.then(function (response) {
		    	context.commit('getCfgColumns', response.data)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    createCfgColumn(context, data) {
    	console.log('action->createCfgColumn')
		return new Promise((resolve, reject) => {
			http.post('/api/cfgcolumns', data)
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    updateCfgColumn(context, data) {
		return new Promise((resolve, reject) => {
			http.patch('/api/cfgcolumns', data)
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    storeCfgColumn(context, data) {
    	console.log('action->storeCfgColumn')
		return new Promise((resolve, reject) => {
			context.dispatch(data.id>0 ? 'updateCfgColumn' : 'createCfgColumn', data)
      		.then((response) => {
				resolve(response)
  			})
	    	.catch((error) => {
				reject(error)
		    });
		})
    },
    sendCfgExec(context, data) {
    	console.log('action->sendCfgExec')
		return new Promise((resolve, reject) => {
			http.post('/api/cfgexec', data)
			.then(function (response) {
		    	if(typeof(response.data) === 'object' && response.data.results instanceof Array) {
					const payload = { command: data.command, results: response.data.results }
		    		context.commit('getCfgCommand', payload)
					resolve(response.data)
				} else reject(error)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },

};

export default actions;