import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import routes from './routes'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!store.getters['auth/loggedIn']) {
			//next()
		} else {
			next()
		}
  } else {
		next()
	}
})

export default router
