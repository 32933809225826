// Footer

<template>
  <v-footer app fixed :inset="false">
    <span>&copy; 2019</span>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  components: {}
};
</script>

<style>
</style>