// auth

import http from './http' 

const login = (credentials) => {
  return new Promise((resolve, reject) =>  {
    http.post('/api/login', {
      username: credentials.username,
      password: credentials.password,
    })
    .then(function (response) {
      //console.log('DEBUG[auth/api]:'+response)
      http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
      resolve(response);
    })
    .catch(function (error) {
      //console.log(error.response)
      reject(error);
    })
  })
};
const getMe = () => {
  return new Promise((resolve, reject) =>  {
    http.get('/api/me')
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log('DEBUG[auth/api]:')
      console.log(error);
      reject(error);
    })
  })
};
const logout = () => {
  return new Promise((resolve, reject) =>  {
    http.post('/api/logout')
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log('DEBUG[auth/api]:')
      console.log(error);
      reject(error);
    })
  })
};

export default {
  login,
  getMe,
  logout,

};
