// files

const getters = {
	getFile(state) {
		return state.file;
	},
	getProgress(state) {
		return state.progress;
	},

};

export default getters;