// MsgDrawer

<template>
    <v-navigation-drawer
		v-model="msg" width="400" fixed right temporary class="blue-grey darken-4"
    >
    </v-navigation-drawer>
</template>

<script>
export default {
	name: 'MsgDrawer',
	components: {},
	data: () => ({
	}),
	computed: {
		msg: {
		    get () {
		      	return this.$store.getters['core/getMsg']
		    },
		    set (value) {
		      	this.$store.dispatch('core/setMsg', value)
		    }
		},
	},
	methods: {},
	mounted() {},
}  
</script>

<style>
</style>