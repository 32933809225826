// core

import http from '../api/http' 

const actions = {
    setMenu(context, menu) {
        context.commit('updateMenu', menu)
    }, 
    setMsg(context, msg) {
        context.commit('updateMsg', msg)
    },
    getPolling(context) {
		return new Promise((resolve, reject) => {
            resolve('tick')
		})
    },

    getCfgs(context) {
		return new Promise((resolve, reject) => {
			http.get('/api/cfgs')
			.then((response) => {
		    	if(response.data instanceof Object) {
					context.commit('getCfgs', response.data)
		    	} else reject(response)
				resolve(response)
			})
			.catch((error) => {
				console.log(error)
				reject(error)
			});
		})
    },

}

export default actions;