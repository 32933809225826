// core

const state = {
	menu: false,
	msg: false,

	menuItems: [
        { icon: 'apps', title: 'Home', to: 'home' },
	],

	cfgTables: [],
	cfgLists: [],
	cfgForms: [],
	cfgRefTables: [],

	//cfgRoutes: [],

};

export default state;