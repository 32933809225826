// auth
import { Ability } from '@casl/ability'

const getters = {
	loggedIn(state) {
		return state.authenticated !== null;
	},
	getMe(state) {
		return state.me;
	},
  
};

export default getters;