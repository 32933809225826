import { AbilityBuilder, Ability } from '@casl/ability'

function subjectName(subject) {
	return !subject || typeof subject === 'string' ? subject : subject.model
}

export const ability = new Ability([], { subjectName })
//export const ability = new Ability()

export default (store) => {
	//const ability = store.getters['auth/ability']
  
	//ability.update(store.state.auth.rules)
	function defineRulesFor(user) {
		//const { rules, can: allow, cannot: forbid } = AbilityBuilder(Ability)
		const { can, cannot, rules } = new AbilityBuilder(Ability)
		if(user.roles.includes('super')) {
			can('manage', 'all')
		} else {
			can('list', 'home')
			for(var permission of user.permissions) {
				let perm = permission.split('.')
				let pact = perm[1]
				let pmod = perm[0]
				let pctx = ''
				if(perm.length == 3) {
					let pctx = perm[2]
				}
				if(pact && pmod) {
					if(pctx == 'user') {
						can(pact, pmod, { id: user.id })
					} else if(pctx == 'team') {
						if(user.teams && Array.isArray(user.teams)) allow(pact, pmod, { team_id: { $in: user.teams } })
						can(pact, pmod, { team_id: user.team_id })
					} else {
						console.log('----->allow', pact, ' -> ', pmod)
						can(pact, pmod)
					}
				}
			}
		}
		return rules
	}
  
	return store.subscribe((mutation) => {
	  switch (mutation.type) {
	  case 'auth/getMe':
		ability.update(defineRulesFor(store.state.auth.me))
		break
	  case 'auth/logout':
		ability.update([])
		break
	  }
	})
}