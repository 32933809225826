// auth

import http from './http' 

const getMe = () => {
  return new Promise((resolve, reject) =>  {
    http.get('/api/me')
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log('DEBUG[auth/api]:')
      console.log(error);
      reject(error);
    })
  })
};
const logout = () => {
  return new Promise((resolve, reject) =>  {
    http.post('/api/logout')
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log('DEBUG[auth/api]:')
      console.log(error);
      reject(error);
    })
  })
};

export default {
  getMe,
  logout,

};
