// items

const getters = {
	getItems: (state) => param => {
		return state.items[param.tablename];
	},
	getItem(state) {
		return state.item;
	},
	getRefItems: (state) => param => {
		return state.refItems[param.tablename];
	},
	getCfgLists(state) {
		return state.CfgLists;
	},

};

export default getters;