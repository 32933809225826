// auth

import api from '../api';

const actions = {
    getMe(context) {
    	//console.log('action->getMe')
		return new Promise((resolve, reject) => {
			api.auth.getMe()
			.then((response) => {
				//console.log(response)
				localStorage.setItem('authenticated', true);
				context.commit('getMe', response.data);
				context.commit('login');
				resolve(response)
			})
			.catch((error) => {
				console.log(error);
				reject(error)
			});
		})
	}, 
    logout(context) {
		api.auth.logout()
	    .then((response) => {
			//console.log(response)
			//localStorage.setItem('authenticated', true);
	    	context.commit('logout', response.data);
	    })
	    .catch((error) => {
      		console.log(error);
	    });
    }, 

}

export default actions;