// GenericRoute

<template>
	<v-container fluid pa-0 ma-0>
		<module  v-if="moduleData.ready" :moduleData="moduleData"></module>
	</v-container>
</template>

<script>
	import module from '../modules'
	export default {
        name: 'GenericRoute',
		components: {
			module,
		},
		data: () => ({
            moduleData: { ready: false },
		}),
		computed: {
		},
		created() {
			this.moduleData.meta = Object.assign({}, this.$route.meta)
			this.moduleData.name = this.$route.name
			this.moduleData.params = this.$route.params
			this.moduleData.modtype = 'generic'
			switch(this.moduleData.meta.type) {
				case 'item':
					this.moduleData.rootComponent = 'GenericView'
				break
				case 'list':
					this.moduleData.rootComponent = 'GenericList'
				break
				default:
					this.moduleData.rootComponent = this.moduleData.name
				break
			}
			this.moduleData.ready = true
		},
	}
</script>

<style>
</style>