// AppBar

<template>
    <v-app-bar
        color="blue-grey darken-2"
        :clipped-left="true"
        dark
        dense
        app
        fixed
    >
        <v-app-bar-nav-icon @click.stop="menu = !menu" class="grey--text text--lighten-1">
        </v-app-bar-nav-icon>
        <v-toolbar-title><span class="font-weight-thin grey--text text--lighten-2">opu</span>
        <span class="font-weight-black grey--text text--lighten-3">cito</span></v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <BreadCrumb /> -->
        <v-spacer></v-spacer>
        <v-btn icon>
            <v-icon class="grey--text text--lighten-1">search</v-icon>
        </v-btn>
        <v-btn icon @click.stop="msg = !msg"><v-badge :value="pollcount" color="blue accent-1" overlap class="align-self-center">
          <template v-slot:badge><span>{{ pollcount }}</span></template>
          <v-icon color="grey lighten-1">mail</v-icon>
        </v-badge></v-btn>
        <v-menu
            left
            offset-y
        >
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon class="grey--text text--lighten-1">settings</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="n in 5"
                    :key="n"
                    @click="() => {}"
                >
                    <v-list-item-title>Option {{ n }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-btn icon @click="logout">
            <v-icon class="grey--text text--lighten-1">power_settings_new</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
import BreadCrumb from './BreadCrumb'; 
export default {
	name: 'AppBar',
	components: {
        BreadCrumb,
    },
	data: () => ({
        title: 'opucito',
        dialog: false,
        polling: null,
        pollcount: 0,
    }),
	computed: {
		menu: {
		    get () { return this.$store.getters['core/getMenu'] },
		    set (value) { this.$store.dispatch('core/setMenu', value) }
		},
		msg: {
		    get () { return this.$store.getters['core/getMsg'] },
		    set (value) { this.$store.dispatch('core/setMsg', value) }
		},
	},
    methods: {
        logout () {
    		this.$store.dispatch('auth/logout')
        	.then(response => {
        		location.reload()
        	})
        },
        pollData () {
            this.polling = setInterval(() => {
                const self = this
                this.$store.dispatch('core/getPolling')
                .then((response) => {
                    console.log(response)
                    self.pollcount++;
                })
                .catch((error) => {
                    console.log(error)
                });
            }, 60000)
        },
    },
	mounted() {
        this.$store.dispatch('core/setMenu', true)
	},
    beforeDestroy () {
	    clearInterval(this.polling)
    },
    created () {
	    this.pollData()
    },
}  
</script>

<style>
</style>