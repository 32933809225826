// core

const mutations = {
	updateMenu(state, menu) {
	    state.menu = menu
	},
	updateMsg(state, msg) {
	    state.msg = msg
	},

	getCfgs(state, data) {
		data.cfgLists.forEach(e => {
			e.value = e.lookupcol ? e.lookupcol + '.name' : e.column_name
			e.text = e.title
			e.align = (e.format == 'default') ? 'start' : (e.format == 'date') ? 'center' : 'end'
			e.sortable = (e.lookupcol) ? false : true
			e.class='blue-grey darken-4'
			e.width='16%'
		})
		data.cfgLists.sort((a, b) => a.pos - b.pos)
		data.cfgForms.forEach(e => {
			e.value = e.lookupcol ? e.lookupcol + '.name' : e.column_name
			e.text = e.title
			e.align = (e.format == 'default') ? 'start' : (e.format == 'date') ? 'center' : 'end'
			e.sortable = (e.lookupcol) ? false : true
		})
		data.cfgForms.sort((a, b) => a.pos - b.pos)
		state.cfgTables = data.cfgTables
		state.cfgLists = data.cfgLists
	    state.cfgForms = data.cfgForms
		state.cfgRefTables = data.cfgRefTables
	},

	/*setCfgRoutes(state, data) {
		state.cfgRoutes = data
	},*/

};

export default mutations;