// cfgtables

const getters = {
	getCfgTables(state) {
		return state.cfgtables;
	},
	getCfgTable(state) {
		return state.cfgtable;
	},
	getCfgColumns(state) {
		return state.cfgcolumns;
	},
	getCfgCommands(state) {
		return state.cfgcommands;
	},

};

export default getters;