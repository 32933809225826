// cfgtables

const mutations = {
	getCfgTables(state, objects) {
  		state.cfgtables = objects;
	},
	getCfgColumns(state, objects) {
		state.cfgcolumns = objects;
  	},
	getCfgTable(state, object) {
		state.cfgtable = object;
	},
	resetCfgTable(state) {
		state.cfgtable = {};
	},
	resetCfgColumn(state) {
		state.cfgcolumn = {};
	},
	getCfgCommand(state, object) {
		state.cfgcommands.push(object);
  	},
	resetCfgCommands(state) {
		state.cfgcommands = [];
	},

};

export default mutations;