import Vue from 'vue'

Vue.filter('toInt', function (value) {
    if (typeof (value * 1) !== "number") {
        return value;
    }
    if (value == undefined) {
        return value;
    }
    return parseFloat(value).toFixed(0);
});

Vue.filter('toCurrency', function (value) {
    if (typeof (value * 1) !== "number") {
        return value;
    }
    if (value == undefined) {
        return value;
    }
    var formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('toIntCurrency', function (value) {
    if (typeof (value * 1) !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    return formatter.format(value);
});

Vue.filter('toMB', function (value) {
    if (typeof (value * 1) !== "number") {
        return value;
    }
    if(value < 100) return value + ' B';
    if((value / 1024) < 100) return parseFloat(value / 1024).toFixed(1) + ' KB';
    return parseFloat(value / (1024 * 1024)).toFixed(1) + ' MB';
});

Vue.filter('toJSDT', function (value) {
  return new Date(value).toISOString().slice(0, 19).replace('T', ' ');
  var a = new Date(value);
  //var months = ['Jan','Feb','Mär','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Dez'];
  var year = a.getFullYear();
  var month = a.getMonth();//months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + '.' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
});

Math.multdec = function ( n, m ) {
	if(!n || !m) {return 0;}
    n = n.replace(/,/g, '.');
    return ( (n * 100) * (m * 100) ) / 10000;
};

Date.prototype.getWeekNumber = function(){
  var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  var w = Math.ceil((((d - yearStart) / 86400000) + 1)/7) + "";
  while (w.length < 2) w = "0" + w;
  var y = d.getUTCFullYear();
  return { week: w, year: y };
};
Date.prototype.getMonday = function(d){
    d = new Date(d);
    var day = d.getDay() || 7,
        diff = d.getDate() - day + 1;
    return new Date(d.setDate(diff)).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit",});
          //.toLocaleString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"})
};
Date.prototype.getDateOfISOWeek = function(w, y){
    var simple = new Date(Date.UTC(y, 0, 1 + (w - 1) * 7));
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}
Date.prototype.getDateEndOfWeek = function(d) {
    d = new Date(d);
    d = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    d.setDate(d.getDate() + 7);
    d.setSeconds(d.getSeconds()  - 1);
    return d;
}
/*var toType = function(obj) {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
};*/