// NavDrawer

<template>
    <v-navigation-drawer style="display:flex;flex-direction:column;"
		v-model="menu"
		:clipped="true"
		width="200"
		fixed
		app
		:temporary="false"
		:expand-on-hover="false"
		mobile-breakpoint="800"
		dark class="blue-grey darken-4"
    >
		<v-list subheader dense>
			<template v-for="(group, index) in groups">
				<v-subheader v-if="group" :key="index" class="grey--text text--darken-1">{{ group }}</v-subheader>
				<template v-for="(item) in groupRoutes(group)">
					<v-list-item :key="item.title" v-if="$can('list', item.name)" @click="goTo(item.name)"
						:class="{'blue-grey darken-3':(item.name === currentRoute)}"
						:disabled="(item.name === currentRoute)" dense>
						<template v-if="(item.name === currentRoute)">
							<v-list-item-icon><v-icon v-text="item.icon" small class="blue--text text--accent-1" />
            				</v-list-item-icon>
							<v-list-item-content><v-list-item-title v-text="item.title" class="blue--text text--accent-1" />
							</v-list-item-content>
						</template>
						<template v-else>
							<v-list-item-icon><v-icon v-text="item.icon" small class="grey--text text--lighten-1" />
            				</v-list-item-icon>
							<v-list-item-content><v-list-item-title v-text="item.title" class="grey--text text--lighten-1" />
							</v-list-item-content>
						</template>
					</v-list-item>
				</template>
			</template>
		</v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
	name: 'NavDrawer',
	components: {},
	data: () => ({
		routes: [],
		groups: [],
	}),
	computed: {
		menu: {
		    get () {
		      	return this.$store.getters['core/getMenu']
		    },
		    set (value) {
		      	this.$store.dispatch('core/setMenu', value)
		    }
		},
		currentRoute() {return this.$route.name},
	},
	methods: {
	 	groupRoutes(group) {
	 		return this.routes.filter(x => x.group === group)
	 	},
		goTo(where) {
			this.menu = false
			this.$router.push({ name: where }).catch(err => {})
			//console.log('currentRoute: ', this.currentRoute)
		},
	},
	mounted() {
		//console.log(this.$router.options.routes)
        for (var route of this.$router.options.routes) {
            if(route.hasOwnProperty('title')) {
                //console.log(route.title + ' -> ' + route.icon);
                this.routes.push(route)
            }
        }
        //console.log('this.routes')
        //console.log(this.routes)
        this.groups = [...new Set(this.routes.map(x => x.group))]
        //console.log('this.groups')
        //console.log(this.groups)
	},
}  
</script>

<style>
</style>