// MainView

<template>
	<v-container fluid pa-0 ma-0>
		<module :moduleType="moduleType" :moduleData="moduleData" v-if="moduleType"></module>
	</v-container>
</template>

<script>
	import module from '../modules'
	export default {
		components: {
			module,
		},
		data() {
			return {
				route: '',
				moduleType: null,
				moduleData: null,
			}
		},
		computed: {
		},
		created() {
			this.route = this.$route
			switch(this.route.name) {
				case 'home':
					this.moduleType = ''
				break
				default:
					this.moduleType = this.route.name
				break
			}
		},
	}
</script>

<style>
</style>