// cfgtables

const state = {
	cfgtables: [],
	cfgtable: {},
	cfgcolumns: [],
	cfgcolumn: {},
	cfgcommands: [],
	
};

export default state;